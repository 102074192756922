<!-- 评论产品信息 -->
<template>
  <div class="remarkproduct">
    <div class="lt">
      <div class="img">
        <img :src="this._src + (popData&&popData.ImagePath?popData.ImagePath:'')">
      </div>
      <div class="h6">
        <h6>{{popData&&popData.ProductTypeName?popData.ProductTypeName:''}}</h6>
      </div>
      <div class="price">
        &yen;{{popData&&popData.Price?popData.Price:''}}
      </div>
    </div>
    <div class="rt">
      <div class="group">
        <div class="rts">
          <p class="time">{{popData&&popData.ProductEvaluateList?popData.ProductEvaluateList[0].ReplyDate:''  | times}}</p>
        </div>
      </div>
      <div class="group">
        <div class="rts">
          <div class="point">
            <div class="rtss" :class="['rtss1',' rtss2',' rtss3','rtss4','rtss5'][this.points[0]-1]">
              <svg ><use xlink:href="#iconwujiaoxing"></use></svg>
              <svg><use xlink:href="#iconwujiaoxing"></use></svg>
              <svg><use xlink:href="#iconwujiaoxing"></use></svg>
              <svg ><use xlink:href="#iconwujiaoxing"></use></svg>
              <svg><use xlink:href="#iconwujiaoxing"></use></svg>
            </div>
            <div class="f" >
              {{this.points[0]}}分
            </div>
          </div>
        </div>
      </div>
      <template  v-for='item,index in popData&&popData.ProductEvaluateList?popData.ProductEvaluateList:""' :keys='index'>
        <div class="group group-detail">
          <div class="rts">
            <p class="infos">{{item.Content}}</p>
          </div>
        </div>
        <div class="group group-detail">
          <div class="rts">
            <div class="lists">
              <div class="img" v-for='items,indexs in item.imglist' :keys='indexs'>
                <img :src="items.ImageUrl | imgsrc(that)" @click='previewDetailimg(items.ImageUrl)'>
              </div>
            </div>
          </div>
        </div>
        <div class="ReplyContent" v-if='item.ReplyContent'>
          <div class="t">平台回复<span>{{item.ReplyDate | times}}</span></div>
          <div class="i">{{item.ReplyContent}}</div>
        </div>
      </template>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'remarkproduct',
  props: {
    popData: Object
  },
  data(){
    return {
      points:[0,0,0,0],//评分值
      dialogImageUrl: '',
      dialogVisible: false,
      that:this,
    }
  },
  methods:{
    previewDetailimg(v){
      // 预览详情图片
      this.dialogImageUrl = this._src + v;
      this.dialogVisible = true;
    },
  },
  mounted(){
  },
  watch:{
    popData(n,o){
      if(n&&n.ProductEvaluateList.length>0){
        // 分数赋值
        this.points[0] = n.ProductEvaluateList[0].Score/2
      }
    },
  },
  filters:{
    times(v){
      if(v){
        return v.split('T')[0]+' '+v.split('T')[1]
      }else{
        return ''
      }
    },
    imgsrc(v,that){
      return that._src+v
    }
  }
}
</script>
<style  lang="scss" scoped>
.remarkproduct {
  background: #fff;display: flex;justify-content: center;padding-bottom:20px;
  .lt {
    width: 337px;border-right: 1px solid #f2f2f2;
    .img {
      display: flex;justify-content: center;height: 170px;align-items: flex-end;margin-bottom:10px;
      img {
        display: block;width: 100px;height: 100px;
      }
    }
    .h6 {
      height: 38px;display: flex;justify-content: center;align-items: center;width: 210px;margin:0 auto;margin-bottom:30px;
      h6 {
        font-weight: normal;font-size: 14px;overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    .price {
      text-align: center;color:#e51c23;font-size: 14px;font-weight: bold;
    }
  }
  .rt {
    width: 862px;padding-top:70px;
    .group {
      margin-bottom:10px;zoom:1;
      .rts {
        width: 640px;float:left;margin-left: 40px;
        .point {
          display: flex;justify-content: flex-start;position: relative;
          .rtss {
            margin-right: 10px;
            svg {
              fill: #e2e2e2;width: 20px;height: 20px;
            }
          }
          .rtss1 {
            svg {
              &:nth-child(1) {
                fill: #e93536;
              }
            }
          }
          .rtss2 {
            svg {
              &:nth-child(-n+2) {
                fill: #e93536;
              }
            }
          }
          .rtss3 {
            svg {
              &:nth-child(-n+3) {
                fill: #e93536;
              }
            }
          }
          .rtss4 {
            svg {
              &:nth-child(-n+4) {
                fill: #e93536;
              }
            }
          }
          .rtss5 {
            svg {
              &:nth-child(-n+5) {
                fill: #e93536;
              }
            }
          }
          .f {
            margin-right: 20px;font-size: 14px;color:#e93536;
          }
          .svgTop {
            position: absolute;top:-20px;left:0px;
            svg {
              width: 18px;height:18px;margin-right: 2px;fill: #e93536;visibility: hidden;
            }
          }
          .svgTop1 {
            svg {
              &:nth-child(1) {
                visibility: visible;
              }
            }
          }
          .svgTop2 {
            svg {
              &:nth-child(2) {
                visibility: visible;
              }
            }
          }
          .svgTop3 {
            svg {
              &:nth-child(3) {
                visibility: visible;
              }
            }
          }
          .svgTop4 {
            svg {
              &:nth-child(4) {
                visibility: visible;
              }
            }
          }
          .svgTop5 {
            svg {
              &:nth-child(5) {
                visibility: visible;
              }
            }
          }
        }
        .time {
          color:#999999;font-size: 14px;
        }
        .infos {
          font-size:14px;color:#101010;
        }
        .lists {
          display: flex;justify-content: flex-start;
          .img {
            width: 50px;height: 50px;border:1px solid #e2e2e2;display: flex;justify-content: center;align-items: center;margin-right: 10px;
            img {
              display: block;max-width: 45px;max-height: 45px;
            }
          }

        }
      }
      &:after {
        display: block;clear: both;content: "";
      }
    }
  }
}
.ReplyContent {
  border-bottom:1px dashed #ccc;padding:10px 0px;padding-left:40px;margin-bottom:10px;
  .t {font-size: 14px;color:#999;
    span {margin-left:10px;}
  }
  .i {font-size: 14px;color:#999;margin-top:5px;}
}
</style>
