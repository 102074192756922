<!-- 订单评价--详情 -->
<template>
  <div class="remarkdetail">
    <remarkinfotitle class='remarkinfotitle'  :popData='rmkData' titles='评价详情'></remarkinfotitle>
    <remarkproduct1 class='remarkproduct1'  :popData='rmkData'></remarkproduct1>
  </div>
</template>
<script>
import remarkinfotitle from '@/components/remarkinfotitle/remarkinfotitle.vue';//顶部hr1
import remarkproduct1 from '@/components/remarkproduct1/remarkproduct1.vue';//评价详情
export default {
  name: 'remarkdetail',
  components: {
    remarkinfotitle,
    remarkproduct1,
  },
  metaInfo() {
    return {
      title:this.htmltitle,
      meta: [
        {
        name: "description",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoDescrition
      },
      {
        name: "Keywords",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoKey
      }
        ]
    }
  },
  data(){
    return {
      htmltitle:'订单评价-微微定',
      rmkData:{}
    }
  },
  props: {
    msg: String
  },
  methods:{
    localReset(){
      this._axios({then:()=>{
        // 请求评论详情数据
        this.axios.post(process.env.VUE_APP_URL+'UserCenter/ObtainProductEvaluate?OrderName='+this._spf(this.$route.query.orderName)+'&ProductId='+this._spf(this.$route.query.ProductType),this.keys({token:this._token({method:'get'})}))
        .then(res => {
            if(res.data.Success){
              // console.log(res.data.Data)
              this.rmkData = res.data.Data

            }else{
              this.$MessageBox.alert(res.data.Error,{type:'error'});
            }
          }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
          })
      },exp:true,catch:()=>{
        this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
        // this._open({path: "/accountlogin",query: {redirect:'/'}})
      }})
    }
  },
  mounted(){
    this.$nextTick(()=>{
      // 加载
      var _this = this;
      let _pomise = this.$store.state.headPomise
      this._reduce([_pomise.a,_pomise.b])
      .then(()=> {
        if(window.sessionStorage.getItem('isReadFirst')){
            window.sessionStorage.removeItem('isReadFirst')
            // this.$store.dispatch("cmtCap",true);//显示隐藏
            $("#app").css('opacity',1)
        }
        window.scrollTo(0,0);//刷新置顶
        console.log('头部加载完成')
        //this._NProgress.done();//关闭加载动画
        this.localReset()
        let _footPomiseA = this.$store.state.footPomiseA
        let _footPomiseB = this.$store.state.footPomiseB
        this._reduce([_footPomiseA,_footPomiseB])
        .then(()=> {
          console.log('内容队列加载')
        })
        .catch(function(e) {
            console.log(e)
        })
      })
      .catch(function(e) {
          console.log(e)
      })
      // end
    })
  }
}
</script>
<style  lang="scss" scoped>
.remarkdetail {
  .remarkinfotitle {
    width: 1200px;margin:0 auto;margin-bottom:25px;
  }
  .remarkproduct1 {
    width: 1200px;margin:0 auto;margin-bottom:10px;
  }
}
</style>
